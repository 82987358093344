.devices-page-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.devices-page-head-title {
    font-size: 32px;
}

.devices-page-btn-add {
    width: 270px;
    height: 40px;
    background-color: #0C8CE9;
    color: #FFFFFF;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
}

.devices-page-btn-add:hover {
    background-color: #007bb5;
}

.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: 20px;
}

.page-item {
    margin: 0 5px;
}

.page-link {
    display: block;
    padding: 8px 16px;
    border: 1px solid #ddd;
    color: #007bff;
    cursor: pointer;
    border-radius: 5px;
}

.page-item.active .page-link {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.page-link:hover {
    background-color: #0056b3;
    color: white;
    border-color: #0056b3;
}

.delete-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    left: 10px;
}

.device-card-wrapper {
    position: relative;
    display: flex;
}