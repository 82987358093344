.block-list, .block-slide{
    width: 345px;
    height: 237px;
    border: 1px solid #A9A9A9;
    border-radius: 20px;
    position: relative;
}

.block-list:hover, .block-slide:hover{
    border: 1px solid #0C8CE9;    
}

.block-list:hover .name-block-list,
.block-slide:hover .name-block-list {
    color: #0C8CE9;
 }

.name-block-list{
    color: #A9A9A9;
    font-size: 24px;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    position: absolute;
    top: 15px;
    left: 21px;
}

.content{
    overflow: auto;
    display: grid;
    gap: 10px;
}

.wrapper{
    position: absolute;
    top: 15px;
    left: 59px;
    overflow: auto;
    height: 207px;
    /* height: 100px; */
    width: 287px;
}

@-moz-document url-prefix() {
    .wrapper {
        scrollbar-width: thin;
        scrollbar-color: #A9A9A9 transparent;
        scrollbar-arrow-color: transparent;
    }
}

.wrapper::-webkit-scrollbar {
    width: 5px;
}
.wrapper::-webkit-scrollbar-track {
background: transparent;
border: 1px solid #A9A9A9;
border-radius: 20px;
}
.wrapper::-webkit-scrollbar-thumb {
background-color: #A9A9A9;
border-radius: 20px;
border: 3px solid #A9A9A9;
}

.content-block-list-row{
    display: grid;
    gap: 2px;
    width: 271px;
}

.block-list-content-title{
    font-size: 16px;
    font-weight: 500;
}

.block-list-content-title-nofirst{
    border-top: 1px solid #A9A9A9;
}

.block-list-content-description{
    font-size: 14px;
}

.block-list-plug{
    height: 207px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.block-list-plug p{
    color: #A9A9A9;
}