.main-page-block-ads {
    width: 100%;
    max-height: 114px; /* Высота для отображения трех новостей */
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 2px; /* Устанавливаем отступ между блоками новостей */
    scrollbar-width: thin; /* Firefox */
}

.main-page-block-ads::-webkit-scrollbar {
    width: 5px; /* Chrome, Safari, Opera */
}

.main-page-block-ads::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid #A9A9A9;
    border-radius: 20px;
}

.main-page-block-ads::-webkit-scrollbar-thumb {
    background-color: #A9A9A9;
    border-radius: 20px;
    border: 3px solid #A9A9A9;
}

.main-page-block-ads-loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-page-block-ad {
    height: 34px;
    line-height: 34px;
    font-size: 20px;
    background-color: rgba(12, 140, 233, 0.1);
    border-radius: 15px;
}

.main-page-block-ad p {
    margin-left: 10px;
}

.important-ad-string {
    display: flex;
    height: 34px;
}

.important-ad-string .main-page-block-ad {
    width: 100%;
}

.ads-container {
    display: flex;
    flex-direction: column;
    gap: 2px; /* Устанавливаем отступ между блоками новостей */
}