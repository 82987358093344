.block-slide {
    width: 345px;
    height: 237px;
    border: 1px solid #A9A9A9;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}

.block-slide.news-block {
    width: 1095px;
}

.block-slide:hover {
    border: 1px solid #0C8CE9;
}

.block-slide:hover .name-block-list {
    color: #0C8CE9;
}

.name-block-list {
    color: #A9A9A9;
    font-size: 24px;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    position: absolute;
    top: 15px;
    left: 21px;
}

.wrapper {
    position: absolute;
    top: 15px;
    left: 59px;
    overflow: auto;
    height: 207px;
    width: 287px;
    scroll-behavior: smooth; /* Плавный скролл */
}

.block-slide.news-block .wrapper {
    width: 1037px;
}

@-moz-document url-prefix() {
    .wrapper {
        scrollbar-width: thin;
        scrollbar-color: #A9A9A9 transparent;
        scrollbar-arrow-color: transparent;
    }
}

.wrapper::-webkit-scrollbar {
    width: 5px;
}
.wrapper::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid #A9A9A9;
    border-radius: 20px;
}
.wrapper::-webkit-scrollbar-thumb {
    background-color: #A9A9A9;
    border-radius: 20px;
    border: 3px solid #A9A9A9;
}

.content {
    overflow: auto;
    display: grid;
    gap: 10px;
}

.block-slide-loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.block-slide .wrapper {
    overflow: visible;
}

.block-slide .wrapper .content {
    gap: 0px;
}

.block-slide-text {
    padding: 0 15px;
    overflow-y: auto;
    overflow-x: hidden; /* Скрыть горизонтальный скролл */
}

.datatime-slide, .hashtage-slide {
    color: #2C2C2C;
    font-family: "PF DinText Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.title-slide {
    color: #2C2C2C;
    font-family: "PF DinText Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
}

.description-slide {
    color: #2C2C2C;
    font-family: "PF DinText Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
    width: 262px;
}

.tags-slide {
    color: #222;
    font-family: "PF DinText Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 10px;
}

.event-type-slide {
    color: #2C2C2C;
    font-family: "PF DinText Pro";
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
}

.event-separator {
    width: 271px;
    height: 1px;
    background: #A9A9A9;
    margin-top: 10px;
}

.slide-btns {
    right: 0;
    bottom: 0;
    cursor: pointer;
}

.block-slide-arrow-left {
    transform: rotate(180deg);
}

.block-slide-img-container {
    max-width: 208px;
    max-height: 208px;
    border-radius: 20px;
    overflow: hidden;
    left: 59px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: lightgray 30% / contain no-repeat;
}

.block-slide-img-container img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.description-slide, .title-slide-news, .title-slide-events {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.title-slide-news {
    -webkit-line-clamp: 2;
}

.title-slide-events {
    -webkit-line-clamp: 5;
}

.hashtage-slide, .event-type-slide {
    margin-top: 10px !important;
    gap: 10px;
}