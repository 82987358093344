.add-employee {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-radius: 20px;
}

.add-employee h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.add-employee-form {
    display: flex;
    gap: 20px;
}

.photo-container {
    flex: 0 0 420px;
    width: 420px;
    height: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
    border: 1px dashed #ccc;
    border-radius: 20px;
    cursor: pointer;
}

.photo-container img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}

.form-columns {
    display: flex;
    gap: 20px;
}

.fields-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.fields-column label {
    font-weight: bold;
}

.custom-input, .custom-select {
    width: 307px;
    height: 40px;
    padding: 7px 20px;
    border: 1px solid #A9A9A9;
    border-radius: 20px;
    outline: none;
    color: #2C2C2C;
    font-weight: 500;
    font-size: 16px;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.save-button {
    display: flex;
    width: 300px;
    height: 40px;
    padding: 7px 20px;
    justify-content: center !important;
    align-items: center !important;
    background: #0C8CE9;
    color: white;
    border-radius: 20px !important;
    border: none;
    cursor: pointer;
    margin-left: 450px;
}

.save-button:disabled {
    background: gray;
    cursor: not-allowed;
}

.message {
    color: green;
    margin-top: 10px;
}

.error {
    color: red;
    margin-top: 10px;
}