.auth-push {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 420px;
    background-color: #FFFFFF;
    border-radius: 20px;
    border: 1px solid #A9A9A9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    z-index: 100;
}

.auth-push-close-img {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: auto;
    cursor: pointer;
}

.auth-push-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.auth-push-input, .auth-push-btn-auth {
    width: 100%;
    height: 40px;
    border-radius: 20px;
}

.auth-push-input {
    border: 1px solid #222222;
    display: flex;
    align-items: center;
    color: #A9A9A9;
    margin-bottom: 15px;
    padding-left: 20px;
    background-color: #FFEB99; /* Цвет фона как на картинке */
}

.auth-push-input input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding-left: 10px;
    border-radius: 20px;
    font-size: 16px;
    color: #000;
    background-color: transparent;
}

.auth-push-btn-auth {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    background-color: #0C8CE9;
    margin-top: 20px;
    cursor: pointer;
}

.auth-push-btn-auth:hover {
    background-color: #007bff;
}

.auth-push-btn-auth-text {
    font-size: 16px;
}

.auth-push-register-btn, .auth-push-remember-btn {
    color: #0C8CE9;
    font-size: 16px;
    font-weight: 500;
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.auth-push-error {
    color: red;
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
}