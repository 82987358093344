

.custom-input{
    height: 100%;
    width: 100%;
    font-family: 'PF DinText Pro';
    height: 40px;
    border: 1px solid #A9A9A9;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    outline: none;
    color: #2C2C2C;
    font-weight: 500;
    font-size: 16px;
    box-sizing: border-box;
}

.custom-input::placeholder{
    font-weight: 400;
    color: #2C2C2C;
}

.custom-input:focus{
    border: 1px solid #0C8CE9;
}

.custom-input:focus::placeholder {
    color: transparent;
}

.custom-input-logo{
    position: absolute;
    width: 24px;
    height: 24px;
    right: 8px;
    top: 8px
}