.devices-single-page {
    padding: 20px;
}

.single-device-content {
    display: flex;
    gap: 30px;
    margin-top: 20px;
}

.single-device-image {
    position: relative;
}

.image-slider {
    position: relative;
    width: 420px;
    height: 420px;
}

.image-slider-wrapper {
    display: flex;
    overflow: hidden;
    width: 420px;
    height: 420px;
}

.image-slide {
    width: 420px;
    height: 420px;
    flex-shrink: 0;
    border-radius: 20px;
    background: lightgray 50% / cover no-repeat;
}

.image-slider-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: -30px;
    width: 100%;
}

.icon-container {
    cursor: pointer;
}

.icon-rotate {
    transform: rotate(180deg);
}

.current-image-index {
    font-size: 16px;
    color: #2C2C2C;
}

.single-device-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 645px;
}

.device-title {
    font-family: "PF DinText Pro", sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #2C2C2C;
}

.device-description {
    font-family: "PF DinText Pro", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #2C2C2C;
}

.devices-info-btns {
    display: flex;
    gap: 30px;
    margin-top: 40px;
    flex-wrap: wrap;
}

.devices-info-btn {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    width: 195px;
    height: 40px;
    border: 1px solid #0C8CE9;
    border-radius: 20px;
    color: #0C8CE9;
    cursor: pointer;
}

.devices-info-btn img {
    width: 24px;
    height: 24px;
}

.devices-info-table {
    margin-top: 30px;
    display: flex;
    gap: 30px;
}

.devices-info-table .column-1,
.devices-info-table .column-2,
.devices-info-table .column-3 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.devices-info-table .column-1,
.devices-info-table .column-3 {
    width: 308px;
}

.devices-info-table .column-2 {
    width: 420px;
}

.devices-info-table-title {
    font-weight: 500;
    margin-bottom: 10px;
}

.devices-info-table-row {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 0;
    border-top: 1px solid #A9A9A9;
}

.devices-info-table-row p:first-child {
    font-weight: 500;
}

.device-btn-look-all {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border: 1px solid #A9A9A9;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 30px;
}

.device-btn-look-all img {
    margin-right: 10px;
}

.device-all-param {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #A9A9A9;
    border-radius: 20px;
}

.device-all-param table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
}

.device-all-param td {
    padding: 5px 10px;
    vertical-align: top;
}

.device-all-param td:first-child {
    color: #2C2C2C;
    font-family: "PF DinText Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 40%;
}

.device-all-param td:last-child {
    color: #2C2C2C;
    font-family: "PF DinText Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}