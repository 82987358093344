.event-left-bottom-column{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 420px;
    
}

.event-left-bottom-row{
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    border-top: 1px solid #A9A9A9;
}

.event-left-bottom-row p:first-child{
    font-weight: 500;
}