.bid-page-head {
  display: flex;
  gap: 30px;
}

.bid-page-head-2 {
  display: flex;
  width: 100%;
}

.filter, .archive {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 110px;
  height: 40px;
}

.filter-worked {
  cursor: pointer;
  justify-content: center;
}

.archive {
  margin-left: auto;
  justify-content: center;
  cursor: pointer;
}

.archive-active {
  border-radius: 20px;
  color: #FFFFFF;
  background-color: #0C8CE9;
}

.archive-active img {
  filter: invert(1) brightness(2);
}

.bid-page-head-tab {
  width: 345px;
  height: 43px;
  font-size: 28px;
  border-bottom: 1px solid #A9A9A9;
  cursor: pointer;
  margin-bottom: 30px;
}

.bid-page-head-tab-selected {
  color: #0C8CE9;
  border-bottom: 1px solid #0C8CE9;
}

.bid-page-btn-add {
  width: 307px;
  height: 40px;
  margin-left: 38px;
  background-color: #0C8CE9;
  color: #FFFFFF;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
}

.news-card-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: flex-start;
}

.news-card-comment {
  display: flex;
  width: 307px;
  height: 195px;
  padding: 7px 40px 7px 20px;
  align-items: flex-start;
  gap: 6px;
  flex-shrink: 0;
  box-sizing: border-box;
}

.news-card-actions {
  display: flex;
  flex-direction: column;
  gap: 7px;
  position: relative;
  bottom: 2px;
  margin-top: 24px;
}

.approve-btn, .reject-btn, .publish-btn, .view-btn, .link-btn {
  display: flex !important;
  width: 200px !important;
  height: 40px !important;
  padding: 7px 40px !important;
  justify-content: center !important;
  align-items: center !important;
  flex-shrink: 0 !important;
  border-radius: 20px;
  border: 1px solid var(---, #6e706e);
  text-decoration: none; /* Убираем подчеркивание у ссылки */
}

.approve-btn {
  background-color: #4CAF50;
  color: white;
}

.approve-btn:hover {
  background-color: #45a049;
}

.reject-btn {
  background-color: #f44336;
  color: white;
}

.reject-btn:hover {
  background-color: #da190b;
}

.publish-btn {
  background-color: #008CBA;
  color: white;
}

.publish-btn:hover {
  background-color: #007bb5;
}

.view-btn {
  background-color: #e7e7e7;
  color: black;
}

.unpublish-btn {
  background-color: #f44336;
  color: white;
}

.unpublish-btn:hover {
  background-color: #da190b;
}

.view-btn:hover {
  background-color: #ddd;
}

.approve-btn img, .reject-btn img, .publish-btn img, .view-btn img {
  margin-right: 5px !important;
}

/* Добавляем стили для иконки глаза */
.news-card-eye-link {
  position: relative;
  top: 3px;  /* Adjust the positioning as needed */
  margin-right: 5px;  /* Add a little margin */
}

.news-card-eye-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  filter: invert(54%) sepia(94%) saturate(2151%) hue-rotate(198deg) brightness(88%) contrast(101%);   /* Blue color */
}

.news-card-edit-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  filter: brightness(1.5) saturate(100%) invert(30%) sepia(100%) saturate(2000%) hue-rotate(200deg) brightness(100%) contrast(97%);
  margin-top: 5px;
}

/* Дополнительные стили, которые могут пригодиться */
.view-btn img {
  display: none;  /* Hide default view icon */
}
