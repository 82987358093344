.header {
    position: absolute;
    top: 30px;
    left: 285px;
    justify-content: end;
    width: 1095px;
    height: 40px;
    font-size: 16px;
}

.header, .header-follow, .header-search, .header-notifications, .header-user {
    display: flex;
    gap: 60px;
}

.header-search, .header-follow, .header-notifications, .header-user {
    border: 1px solid #A9A9A9;
    border-radius: 20px;
    height: 40px;
    align-items: center;
    justify-content: center;
}

.header-search {
    width: 308px;
    justify-content: space-between;
    color: #2C2C2C;
}

.header-follow {
    display: flex;
    width: 45px;
    height: 24px;
    padding: 7px 40px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid var(--, #0C8CE9);
    cursor: pointer;
    color: #0C8CE9;
    margin-left: 10px; /* Отступ от .header-search */
    margin-right: 10px; /* Отступ от .header-notifications */
}

.header-follow:hover {
    background-color: #0C8CE9;
    color: #FFFFFF;
}

.header-notifications {
    width: 60px;
    border: none;
    cursor: pointer;
}

.header-search p {
    margin-left: 1.38vw;
}

.header-search img {
    margin-right: 1.38vw;
}

.header img {
    width: 34px;
    height: 34px;
}

.header-user {
    width: 50px;
    border: none;
    cursor: pointer;
    position: relative;
}

.header-user-img, .header-user-initials {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    object-fit: cover;
    color: white;
    background-color: #0C8CE9;
}

.header-user-menu {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
    width: 200px; /* Увеличиваем ширину для отображения роли */
}

.header-user-menu-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

.header-user-menu-item:last-child {
    border-bottom: none;
}

.header-user-menu-item:hover {
    background-color: #f1f1f1;
}
