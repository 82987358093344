.device-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.device-form input,
.device-form textarea,
.device-form select {
    padding: 10px;
    border: 1px solid #A9A9A9;
    border-radius: 5px;
}

.form-buttons {
    display: flex;
    justify-content: space-between;
}

.close-btn,
.submit-btn,
.upload-btn {
    background-color: #0C8CE9;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    flex: 1;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.close-btn {
    background-color: #ff4d4d;
}

.upload-btn {
    background-color: hsl(114, 71%, 27%);
}

.close-btn:hover {
    background-color: #ff1a1a;
}

.submit-btn:hover {
    background-color: #007bb5;
}

.upload-btn:hover {
    background-color: #45a049;
}

.upload-btn img {
    width: 24px;
    height: 24px;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
}

.progress-bar-fill {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.2s;
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-container p {
    margin-left: 10px;
    font-size: 16px;
}