.welcome-text{
    text-align: start;
    font-size: 32px;
}

.main-page-head{
    display: flex;
    justify-content: space-between;
}

.main-page-head img{
    cursor: pointer;
}

.main-page-content{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    gap: 30px;
    margin-top: 30px;
}

.news, .main-page-block-ads{
    grid-column: span 3;
}

.main-page-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border: 1px solid #A9A9A9;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
}

.main-page-btn:hover{
    border-color: #0C8CE9;
    color: #0C8CE9;
}

.main-page-btn:hover img, .edit-main-page-img:hover{
    filter: invert(30%) sepia(100%) saturate(5000%) hue-rotate(190deg) brightness(95%);
}

.main-page-btn-red{
    border: 1px solid #C73B3B;
    color: #C73B3B;
}

.main-page-btn-red img {
    filter: invert(25%) sepia(83%) saturate(628%) hue-rotate(327deg) brightness(85%) contrast(100%);
}

.main-page-block-ads{
    width: 100%;
    max-height: 114px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.main-page-block-ads-loader{
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-page-block-ad{
    height: 34px;
    line-height: 34px;
    font-size: 20px;
    background-color: rgba(12, 140, 233, 0.1);
    border-radius: 15px;
}

.main-page-block-ad p{
    margin-left: 10px;
}

.important-ad-string{
    display: flex;
    height: 34px;
}

.important-ad-string .main-page-block-ad{
    width: 100%;
}

.block-list, .block-slide{
    width: 345px;
    height: 237px;
    border: 1px solid #A9A9A9;
    border-radius: 20px;
    position: relative;
}

.block-list:hover, .block-slide:hover{
    border: 1px solid #0C8CE9;    
}

.block-list:hover .name-block-list,
.block-slide:hover .name-block-list {
    color: #0C8CE9;
}

.name-block-list{
    color: #A9A9A9;
    font-size: 24px;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    position: absolute;
    top: 15px;
    left: 21px;
}

.content{
    overflow: auto;
    display: grid;
    gap: 10px;
}

.wrapper{
    position: absolute;
    top: 15px;
    left: 59px;
    overflow: auto;
    height: 207px;
    width: 287px;
}

@-moz-document url-prefix() {
    .wrapper {
        scrollbar-width: thin;
        scrollbar-color: #A9A9A9 transparent;
        scrollbar-arrow-color: transparent;
    }
}

.wrapper::-webkit-scrollbar {
    width: 5px;
}
.wrapper::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid #A9A9A9;
    border-radius: 20px;
}
.wrapper::-webkit-scrollbar-thumb {
    background-color: #A9A9A9;
    border-radius: 20px;
    border: 3px solid #A9A9A9;
}

.content-block-list-row{
    display: grid;
    gap: 2px;
    width: 271px;
}

.block-list-content-title{
    font-size: 16px;
    font-weight: 500;
}

.block-list-content-title-nofirst{
    border-top: 1px solid #A9A9A9;
}

.block-list-content-description{
    font-size: 14px;
}

.block-list-plug{
    height: 207px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.block-list-plug p{
    color: #A9A9A9;
}

.block-slide-loader{
    display: flex;
    justify-content: center;
    align-items: center;
}

.block-slide .wrapper{
    overflow: visible;
}

.block-slide .wrapper .content{
    gap: 0px;
}

.datatime-slide, .hashtage-slide{
    font-weight: 500;
}

.title-slide{
    font-size: 20px;
}

.event-type-slide{
    margin-top: 48px;
    font-style: italic;
    bottom: -5px;
}

.slide-btns{
    right: 0;
    bottom: 0;
    cursor: pointer;
}

.block-slide-arrow-left{
    transform: rotate(180deg);
}

.block-slide-img-container{
    max-width: 208px;
    max-height: 208px;
    border-radius: 20px;
    overflow: hidden;
    left: 59px;
    top: 0px;
}

.block-slide-img-container img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.description-slide, .title-slide-news, .title-slide-events {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.title-slide-news{
    -webkit-line-clamp: 2;
}

.title-slide-events{
    -webkit-line-clamp: 5;
}

.hashtage-slide, .event-type-slide{
    margin-top: 10px !important;
    gap: 10px;
}