.custom-photobox-container{
    width: 195px;
    height: 195px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #A9A9A9;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.custom-photobox-container input{
    width: 1px;
    height: 1px;
    opacity: 0;
}

.custom-photobox-selected-photo{
    height: 100%;
    width: auto;
}

.custom-photobox-icon{
    display: block;
}

.custom-photobox-icon-selected{
    display: none;
}

.bid-image-delete-container{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 50;
    border-radius: 20px;
    background-color: #FFFFFF;
}

.bid-image-delete, .bid-image-delete:hover, .bid-image-delete-container:hover img{
    filter: sepia(1) saturate(10000%) brightness(1.5) hue-rotate(-10deg);
}