body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'PF DinText Pro';
  font-weight: normal;
  font-style: normal;
}

.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

a {
  all: unset;
  display: inline; /* Устанавливаем тип отображения, так как all: unset сбрасывает его */
  cursor: pointer; /* Устанавливаем курсор в виде руки, так как all: unset может сбросить это */
  text-decoration: none; /* Убираем подчеркивание, если нужно */
  color: inherit; /* Унаследовать цвет текста от родителя */
}

textarea{
  font-family: 'PF DinText Pro';
  font-weight: normal;
  font-style: normal;
}

.ck-powered-by {
  display: none !important;
}





