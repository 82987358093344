p{
  margin: 0;
}

#root{
  display: grid;
  justify-items: center
}

.App{
  position: relative;
  width: 1440px;
  height: 976px;
}

.page-content{
  width: 1095px;
  position: absolute;
  top: 125px;
  left: 285px;
  display: flex;
  flex-direction: column;
}

.bg-close{
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  /* background-color: #00000038; */
  z-index: 25;
}

.plug{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-container{
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon-container img{
  height: 24px;
  width: 24px;
}

.icon-container:hover img{
  filter: invert(30%) sepia(100%) saturate(5000%) hue-rotate(190deg) brightness(95%);
}

.icon-rotate{
  transform: rotate(90deg);
}

.non-active-img, .non-active-img:hover, .non-active-img-container:hover img { 
  filter: invert(100%) grayscale(100%) brightness(70%);
  cursor: default;
}

.non-active-img-container {
  cursor: default;
}

@-moz-document url-prefix() {
  .custom-scrollbar {
      scrollbar-width: thin;
      scrollbar-color: #A9A9A9 transparent;
      scrollbar-arrow-color: transparent;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.custom-scrollbar::-webkit-scrollbar-track {
background: transparent;
border: 1px solid #A9A9A9;
border-radius: 20px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
background-color: #A9A9A9;
border-radius: 20px;
border: 3px solid #A9A9A9;
}

