.standart-card{
    display: grid;
    gap: 5px;
    height: 220px;
    width: 533px;
}

.bid-list-card-info-bar{
    display: flex;
    justify-content: space-between;
}

.bid-list-info-bar-card-column-2{
    display: flex;
    gap: 15px;
    align-items: center;
}

.bid-card-info-bar-status, 
.bid-card-info-bar-public-date,
.bid-list-card-title{
    font-weight: 500;
}

.bid-card-info-bar-event-type{
    font-size: 14px;
}

.events-color-line{
    width: 410px;
    height: 10px;
    border-radius: 10px;
}

.bid-list-card-content{
    display: flex;
    gap: 15px;
    border: 1px solid #A9A9A9;
    border-radius: 20px;
    height: 195px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.bid-list-card-img-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 195px;
    width: 195px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
}

.bid-list-card-img-container img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.bid-list-card-content-column-2{
    display: grid;
    gap: 10px;
    margin-right: 15px;
    width: 308px;
}

.bid-list-card-text{
    font-size: 14px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; /* Устанавливаем количество строк */
    overflow: hidden; /* Скрываем текст, выходящий за пределы */
    max-height: calc(1.2em * 4); /* 1.2em — высота строки, умноженная на количество строк */
    line-height: 1.2em; /* Высота строки */
}